import CleaningImage from "../images/categories/cleaning.png";
import AssemblyImage from "../images/categories/assembly.png";
import HandymanImage from "../images/categories/handyman.png";
import DeliveryImage from "../images/categories/delivery.png";
import GardeningImage from "../images/categories/gardening.png";
import AdminImage from "../images/categories/admin.png";
import MoversImage from "../images/categories/removalists.png";
import ComputerImage from "../images/categories/computer.png";
import CameraImage from "../images/categories/photography.png";
import OthersImage from "../images/categories/custom.png";
import PainterImage from "../images/categories/painting.png";
import LiftingImage from "../images/categories/lifting.png";
import Assembly from "../images/category_photos/assembly.jpeg";
import Painting from "../images/category_photos/painting.jpeg";
import Hauling from "../images/category_photos/hauling.jpg";
import Delivery from "../images/category_photos/delivery.jpg";
import SnowRemoval from "../images/category_photos/snow_removal.jpg";
import Cleaning from "../images/category_photos/cleaning.jpg";
import Tutoring from "../images/category_photos/tutoring.jpg";
import Computer from "../images/category_photos/computer_it.jpg";
import HeavyLifting from "../images/category_photos/lifting.jpg";
import Yardwork from "../images/category_photos/yardwork.jpg";
import Handyman from "../images/category_photos/handyman.jpeg";
import Admin from "../images/category_photos/admin.jpg";
import Custom from "../images/category_photos/custom.jpg";
import Photography from "../images/category_photos/photography.jpg";

export const getCategoryImage = (category: string) => {
    if (category)
        switch (category.toLowerCase()) {
            case "cleaning":
                return CleaningImage;
            case "assembly":
                return AssemblyImage;
            case "handyman":
                return HandymanImage;
            case "delivery":
                return DeliveryImage;
            case "yardwork":
                return GardeningImage;
            case "admin":
                return AdminImage;
            case "hauling":
                return MoversImage;
            case "computer it":
            case "computer-it":
            case "computerit":
                return ComputerImage;
            case "photography":
                return CameraImage;
            case "others":
                return OthersImage;
            case "painting":
                return PainterImage;
            case "lifting":
                return LiftingImage;
            default:
                return OthersImage;
        }
    else
        return OthersImage;
};

export const categories = (): string[] => {
    return [
        "cleaning",
        "assembly",
        "handyman",
        "delivery",
        "yardwork",
        "hauling",
        "admin",
        "computer it",
        "photography",
        "lifting",
        "painting",
        "others",
    ]
}

export const homeCategories = (): string[] => {
    return [
        "cleaning",
        "photography",
        "admin",
        "yardwork",
        "assembly",
        "hauling",
        "delivery"
    ]
}

export const getImagePosition = (category: string): string => {
    switch (category) {
        case "photography":
        case "cleaning":
            return "left center"
        case "assembly":
        case "yardwork":
        case "admin":
        case "hauling":
            return "35% center"
        case "delivery":
            return "30% center"
        default:
            return "left center"
    }
}

export const getCategoryLink = (category: string) => {
    if (category)
        switch (category.toLowerCase()) {
            case "admin":
            case "cleaning":
            case "assembly":
            case "handyman":
            case "delivery":
            case "painting":
            case "hauling":
            case "photography":
                return category.toLowerCase();
            case "lifting":
                return "heavy-lifting";
            case "yardwork":
                return "yard-work"
            case "computer it":
                return "computer-it";
            case "snow-removal":
                return "snow-removal"
            case "others":
                return "custom";
        }
    else
        return OthersImage;
};

export const getImage = (location: string) => {
    switch (location) {
        case "assembly":
            return Assembly;
        case "handyman":
            return Handyman;
        case "painting":
            return Painting;
        case "hauling":
            return Hauling;
        case "delivery":
            return Delivery;
        case "snow-removal":
            return SnowRemoval;
        case "cleaning":
            return Cleaning;
        case "tutoring":
            return Tutoring;
        case "computer-it":
            return Computer;
        case "heavy-lifting":
            return HeavyLifting;
        case "yardwork":
        case "yard-work":
            return Yardwork;
        case "admin":
            return Admin;
        case "custom":
            return Custom;
        case "photography":
            return Photography;
        default:
            return Handyman;
    }
}