
import React from "react";
import Text from "../../data-display/text"
import Avatar from "../../avatar/avatar";
import { Grid} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { FaStar } from "react-icons/fa";
import makeStyles from '@material-ui/core/styles/makeStyles';
import { GrayColor } from "../../../constants/constant-ui";
import { Calendar, Globe, MapPin } from "react-feather";
import CleaningImage from "../../../images/categories/cleaning.png";
import AssemblyImage from "../../../images/categories/assembly.png";
import Handyman from "../../../images/categories/handyman.png";
import DeliveryImage from "../../../images/categories/delivery.png";
import GardeningImage from "../../../images/categories/gardening.png";
import AdminImage from "../../../images/categories/admin.png";
import MoversImage from "../../../images/categories/removalists.png";
import ComputerImage from "../../../images/categories/computer.png";
import CameraImage from "../../../images/categories/photography.png";
import OthersImage from "../../../images/categories/custom.png";
import PainterImage from "../../../images/categories/painting.png";
import SnowImage from "../../../images/categories/snow.png";
import LiftingImage from "../../../images/categories/lifting.png";


const useStyles = makeStyles((theme) => ({
    container: {
         paddingTop: '24px',
    },
    PostTaskButton: {
         marginLeft: "12px",
         transition: "0.3s",
         borderRadius: "50px",
         cursor: "pointer",
         padding: "6px 14px",
         display: "flex",
         alignItems: "center",
         color: theme.palette.primary.main,
         "&:hover": {
              background: "rgba(78, 62, 253,0.13)"
         }
    },

    drawerPaper: {
         width: 'calc(1280px/3)',
         left: 'calc((100% - 1280px)/2)',
         height: 'calc(100vh - 88px)',
         border: 'none',
         background: 'none',
         top: '88px',
    },
    card: {
         display: "flex",
         flexDirection: "column",
         background: "white",
         position: "relative",
         borderRadius: "6px",
         cursor: "pointer",
         boxShadow: "0 1px 2px rgb(0,0,0,0.1)",
         transition: "0.3s",
         border: "1.5px solid rgb(245,245,245)",
         "&:hover": {
              boxShadow: "0 10px 20px -14px rgba(50,50,50,0.3)"
         }
    },
    selectedCard: {
         boxShadow: "0 10px 20px -14px rgba(50,50,50,0.3)",
         border: "1.5px solid rgb(220,220,220)"
    },
    formControl: {
         marginTop: '24px',
         minWidth: 120,
         width: '300px',
         background: 'transparent',
         '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
              border: '2px solid #d3d3d4',
              background: 'transparent',
         },
         '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: '2px solid #d3d3d4',
              background: 'transparent',
         },
    }, statusIndicator: {
         borderRadius: '24px',
         padding: '1.5px 12px',
         paddingBottom: "3.5px",
         textAlign: 'center',
         display:"flex",
         justifyContent:"space-between"
    },
    selectRoot: {
         padding: '12px',
         display: 'flex',
         alignItems: 'center',
    },
    listItemRoot: {
         minWidth: '40px',
    },
    dropdownStyle: {
         border: '2px solid #d3d3d4',
         boxShadow: 'none',
    },
    filtersContainer: {
         position: 'fixed',
         width: 'calc(1280px/3.1)',
         paddingBottom: '10px',
         background: theme.palette.background.default,
         zIndex: 1300,
         left: 'calc((100% - 1280px)/2)',
    },
    rootTabContainer: {
         transform: 'translateY(4px)',
         background: 'white',
         padding: '0 24px',
         borderRadius: '6px',
    },
    rootTab: {
         textTransform: 'none',
         padding: '0',
         minWidth: 'unset',
         justifyContent: 'flex-start !important',
         fontSize: theme.typography.pxToRem(15),
         marginRight: '48px',
    },
    indicatorTab: {
         display: 'flex',
         height: '4px',
         justifyContent: 'center',
         backgroundColor: theme.palette.primary.main,
         '& > div': {
              width: '100%',
              backgroundColor: theme.palette.primary.main,
         },
    }
}));

const TaskCard = ({ task }: { task: any }) => {

    const theme = useTheme();
    const xsDown = useMediaQuery(theme.breakpoints.down("xs"));
    const classes = useStyles();

    const getCategoryImage = (category: string) => {
        if (category)
            switch (category.toLowerCase()) {
                case "cleaning":
                    return CleaningImage;
                case "assembly":
                    return AssemblyImage;
                case "handyman":
                    return Handyman;
                case "delivery":
                    return DeliveryImage;
                case "yardwork":
                    return GardeningImage;
                case "admin":
                    return AdminImage;
                case "hauling":
                    return MoversImage;
                case "computer it":
                case "computer-it":
                case "computerit":
                    return ComputerImage;
                case "photography":
                    return CameraImage;
                case "others":
                    return OthersImage;
                case "painting":
                    return PainterImage;
                case "snow-removal":
                case "snow removal":
                    return SnowImage;
                case "lifting":
                    return LiftingImage;
                default:
                    return OthersImage;
            }
        else return OthersImage;
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", overflow: "hidden", background: "rgb(255,255,255)", width: "100%", boxShadow: xsDown ? "" : "0 8px 46px -14px rgba(0,0,0,0.07)", borderRadius: '8px', height: "100%", marginBottom: xsDown ? "8px" : "" }}>
            <div style={{ display: "flex", alignItems: "flex-start", padding: "8px 16px" }}>
                <img loading="lazy" alt={task.category} width="32px" height="32px" src={getCategoryImage(task.category).toString()} style={{ marginRight: "18px", marginTop: "6px" }} />
                <div>
                    <Text variant={"overline"} medium style={{ opacity: "0.5", lineHeight: 1 }}>
                        {task?.category}
                    </Text>
                    <Text variant={"body2"} style={{ lineHeight: "1.2" }}>
                        {task?.taskTitle}
                    </Text>
                    <Text variant={"body2"} style={{ lineHeight: "1.2" }}>
                        {task?.title2}
                    </Text>
                    <Text variant={"body2"} style={{ lineHeight: "1.2" }}>
                        {task?.title3}
                    </Text>
                    <div style={{ height: "3px" }} />
                    {task.isRemote ? (
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Globe color={GrayColor} size={10} />
                            <div style={{ width: "12px" }} />
                            <Text variant={"caption"} style={{ color: "grey" }} medium>
                                Remote
                            </Text>
                        </div>
                    ) : (
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <MapPin color={GrayColor} size={10} />
                            <div style={{ width: "12px" }} />
                            <Text variant={"caption"} style={{ color: "grey" }} medium>
                                {task.city}
                            </Text>
                        </div>
                    )}
                    <div style={{ height: "3px" }} />
                </div>
                <div style={{ marginLeft: "auto" }} />
                <div style={{ width: "12px" }} />
                <Text variant={"body2"} bold style={{ lineHeight: "1.1", marginRight: "2px", marginTop: "4px" }} >
                    ${task.budgetByHour ?task.budgetByHour +"/hr" :task.budget } 
                </Text>
            </div>
            <div style={{ marginTop: "auto" }} />
            <div style={{borderTop: "1px solid rgb(240,240,240)",display: "flex", justifyContent: "space-between", padding: "8px 16px", paddingBottom: "14px", marginTop: "auto", marginBottom: "auto" }}>
              <div style={{ display: "flex", flexDirection: "column", width: "fit-content", marginBottom: "auto", marginLeft: "15px" }}>
                   <div style={{ display: "flex", flexDirection: "row", }}>
                   <Text variant={"subtitle1"} medium style={{ opacity: "0.5", transform: "translateY(1.5px)" }} >
                    5 Stars
                </Text>
                <FaStar color={"rgb(255, 186, 0)"} style={{ width: `12px`, height: `12px`, marginLeft: "6px" }} />
                   </div>
              </div>
            <div style={{ display: "flex", cursor: "pointer", flexDirection: "column", width: "fit-content", marginBottom: "auto", marginRight: "15px" }}>
            <Text style={{ border: "2px solid #bdbdbd", wordBreak: "unset", color: "rgb(160,160,160)" }} className={classes.statusIndicator} variant={"caption"} bold>
            Completed
          </Text>
              </div>
              
         </div>
            {/*<Text variant={"body2"} regular>*/}
            {/*    "{review?.text.trim()}"*/}
            {/*</Text>*/}
        </div>
    )
}

export default TaskCard;