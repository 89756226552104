import React, { useEffect, useRef, useState } from "react"
import { useMediaQuery, useTheme } from "@material-ui/core"
import Flickity from "react-flickity-component"
import "./scrolling-tasks.css"
import f from "./featured-tasks.json";
import TaskCard from "./task-card";
import { any } from "prop-types";
import { Link, navigate } from "gatsby";
import SEO from "../../seo/seo";
const ScrollingTasks = ({ tasks, reverse = false }: { tasks:any, reverse?: boolean }) => {

    const theme = useTheme();
    const xsDown = useMediaQuery(theme.breakpoints.down('xs'));

    const [flickity, setFlickity] = useState<Flickity | null>(null);
    const [flickityInterval, setFlickityInterval] = useState<NodeJS.Timeout>(null);
    const progressBar = useRef<HTMLDivElement>(null);
    const progressBarBackground = useRef<HTMLDivElement>(null);
    const height = xsDown ? "130px" : "155px";

    useEffect(() => {
        if (flickity)
            flickity.on('scroll', function (progress: number) {
                if (progressBar.current && progressBarBackground.current) {
                    progress = Math.max(0, Math.min(1, progress));
                    progressBar.current.style.width = (progress * 100) + "%";
                }
            });

        if (flickity) {
            play();
        }
    }, [flickity]);

    const play = () => {
        if (flickity && !flickityInterval) {
            let c = setInterval(marquee, 30)
            setFlickityInterval(c);
        } else {

        }
    }

    const marquee = () => {
        // @ts-ignore
        let t;
        // if (continueX)
        //     t = continueX;
        // else
        t = flickity["x"];
        flickity["x"] = t + (!reverse ? 0.5 : (- 0.5));
        // @ts-ignore
        flickity.settle(flickity["x"])
    }

    // const pause = () => {
    //     if (flickity && flickityInterval) {
    //         flickity["x"] = continueX - 0.5;
    //         setContinueX(continueX-0.5);
    //         clearInterval(flickityInterval);
    //         // @ts-ignore
    //         flickity.settle(flickity["x"])
    //         setFlickityInterval(null);
    //     }
    // }

    return (
        <div style={{ height: height, padding: "10px 0", width: "100%", boxSizing: "unset", overflow: "hidden" }}>
            <Flickity
                flickityRef={el => setFlickity(el)}
                options={{ draggable: false, friction: 0.4, freeScroll: true, prevNextButtons: false, pageDots: false, wrapAround: true, cellAlign: "left", percentPosition: true, setGallerySize: true, accessibility: true, resize: true }}
                reloadOnUpdate
                disableImagesLoaded
            >
                {
                    tasks.map((t) => {
                        return (
                            <>
                            <Link to={`/tasks/${t.id}`}>
                            <div  key={t.id} style={{ height: height, width: "330px", marginRight: "24px", overflow: "hidden", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <TaskCard task={t} />
                            </div>
                             </Link>
                             </>
                        )
                    })
                }
            </Flickity>
        </div>
    )

}

export default ScrollingTasks;